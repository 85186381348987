const MapComponent = () => {
    return (
      <div
        style={{
          display: "flex",
          flexWrap: "wrap",
          justifyContent: "center",
          alignItems: "center",
          width: "100%",
          gap: "20px",
          padding: "20px"
        }}
      >
        {/* Clínica 1 */}
        <div style={{ flex: "1 1 500px", maxWidth: "600px", width: "100%" }}>
          <h3>Clínica - Recreio dos Bandeirantes</h3>
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d14689.262750357582!2d-43.44879449575664!3d-23.012178353998948!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x9bdd2441e1466b%3A0x27c9a40d582f10bd!2sAv.%20das%20Am%C3%A9ricas%2C%2016.285%20-%20Sala%20208%20-%20Recreio%20dos%20Bandeirantes%2C%20Rio%20de%20Janeiro!5e0!3m2!1spt-BR!2sbr!4v1724070321228!5m2!1spt-BR!2sbr"
            width="100%"
            height="300"
            style={{ border: 0 }}
            allowFullScreen=""
            loading="lazy"
            referrerPolicy="no-referrer-when-downgrade"
            title="Mapa Clínica Recreio"
          />
        </div>
  
        {/* Clínica 2 */}
        <div style={{ flex: "1 1 500px", maxWidth: "600px", width: "100%" }}>
          <h3>Clínica - Centro de Três Rios</h3>
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d14689.262750357582!2d-43.2093!3d-22.1166!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x9e9973b8d7e7e5%3A0x1c6f1fbb74a25d9a!2sR.%20Rita%20Cerqueira%2C%2033%2C%20SALA%3A301%20-%20Guerra%20Rodrigues%2C%20Centro%2C%20Tr%C3%AAs%20Rios%20-%20RJ!5e0!3m2!1spt-BR!2sbr!4v1724070321228!5m2!1spt-BR!2sbr"
            width="100%"
            height="300"
            style={{ border: 0 }}
            allowFullScreen=""
            loading="lazy"
            referrerPolicy="no-referrer-when-downgrade"
            title="Mapa Clínica Três Rios"
          />
        </div>
      </div>
    );
  };
  
  export default MapComponent;
  